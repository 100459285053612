<template>
  <b-card title="Diplomas SEO">
    <b-tabs>
      <b-tab
      v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='seo-Diplomas-Classical' ||currentRouteName=='seo-online-Diplomas'
          ||currentRouteName=='seo-Diplomas-in-city'
        "
        @click="$router.push('/seo-Diplomas-Classical')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Seo Diplomas</span>
        </template>

      
      </b-tab>

   
       <!-- <SeoCity></SeoCity> -->

     
   
       <!-- <SeoCategryInCity></SeoCategryInCity> -->

     
     
    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <SeoClassicalCourse v-if="route.name === 'seo-Diplomas-Classical' && role.roles[0]==='Supervisor' || role.roles[0]=='Super Admin'"  />
        
      <router-view  v-else>
          <component :is="Component" />
        </router-view>
  </router-view>
  
   
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText ,BCard} from 'bootstrap-vue'
import SeoClassicalCourse from './seocourse/SeoClassicalCourse.vue';

import SeoCity from './SeoCity.vue';




export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    SeoClassicalCourse
    // CategoriesList,
    ,

    SeoCity,

},
  data() {
    return {
   role:{}
    }
  },
  created(){
    this.role=JSON.parse(localStorage.getItem('userData'))
  }
  ,
   computed: {
    currentRouteName() {
        return this.$route.name;
    }}
}
</script>
